//import css
import "./scss/main.scss";

//import components js
import "./components/col4-icons/col4-icons.js";
import "./components/contact-form/contact-form.js";
import "./components/footer/footer.js";
import "./components/fullscreen-slider/fullscreen-slider.js";
import "./components/header/header.js";
import "./components/heading/heading.js";
import "./components/icon/icon.js";
import "./components/masthead/masthead.js";
import "./components/popup-consent/popup-consent.js";
import "./components/skiplinks/skiplinks.js";
import "./components/text-module/text-module.js";

import "./js/browser-detect";
//import "./js/lazyload";
